// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import socialIconsReducer from './socialIconsSlice'; 

export const store = configureStore({
  reducer: {
    socialIcons: socialIconsReducer, 
    // Add more reducers here as you create more slices
  },
});
