// socialiconSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [],
};

const socialIconsSlice = createSlice({
  name: 'socialIcons',
  initialState,
  reducers: {
    setSocialIcons: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setSocialIcons } = socialIconsSlice.actions;

export default socialIconsSlice.reducer;
